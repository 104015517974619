<template>
    <div class="content-wrapper">
        <PageHeader :screenName="pageTitle" :linkItems="linkItems"/>

        <main class="card p-2" :class="{'opacity-50': loadingData}">
            <section class="infos mb-4">
                <b-row class="mb-2">
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">Nome da Unidade Operacional</label>
                        <p class="font-size-14">{{ unidade.name }}</p>
                    </b-col>
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">Código para integração externa</label>
                        <p class="font-size-14">{{ unidade.codeExternalIntegration }}</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">Código SMD da Unidade Operacional</label>
                        <p class="font-size-14">{{ unidade.smdCodeUnit }}</p>
                    </b-col>
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">CNPJ da Unidade Operacional</label>
                        <p v-if=" unidade.cnpj" class="font-size-14">{{  unidade.cnpj }}</p>
                        <p v-else class="text-danger">-</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">Departamento Regional</label>
                        <p class="font-size-14">{{ unidade.regionalDepartment }}</p>
                    </b-col>
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">Cidade (local físico)</label>
                        <p v-if=" unidade.city" class="font-size-14">{{  unidade.city }}</p>
                        <p v-else class="text-danger">-</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">Região</label>
                        <p class="font-size-14">{{ unidade.region }}</p>
                    </b-col>
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">Responsável por esta Unidade Operacional</label>
                        <p v-if="unidade.responsible" class="font-size-14">{{ unidade.responsible }}</p>
                        <p v-else class="text-danger">-</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">E-mail</label>
                        <p v-if="unidade.email" class="font-size-14">{{ unidade.email }}</p>
                        <p v-else class="text-danger">-</p>
                    </b-col>
                </b-row>
                <hr/>
                <b-row class="mb-2 d-flex flex-column">
                    <label class="ml-1 font-weight-bold mb-1 font-size-15">Cidades Vinculadas</label>
                    <b-col v-if="linkedCities.length > 0" class="d-flex flex-wrap">
                        <div v-for="city in linkedCities" :key="city.id_cidade" class="rounded cities custom">
                            {{ city.nome }}
                        </div>
                    </b-col>
                    <p v-else class="text-danger">-</p>
                </b-row>
            </section>
            <section class="buttons">
                <b-button id="redirect-edit" 
                    class="mr-2" 
                    variant="custom-blue"
                    v-if="canEdit"
                    @click.prevent="redirectToEdit"
                    :disabled="loadingData"
                >
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Editar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BRow, BCol, BButton } from 'bootstrap-vue';

import { getIdUnidade, getAcronymUnidade } from '@core/utils/store/getStore';
import { setIdUnidade, setAcronymUnidade, setListFilter } from "@core/utils/store/setStore";
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';

export default {
    title: 'Detalhes unidade',
    
    components: {
        PageHeader,
        BRow, BCol, BButton
    },

    data() {
        return {
            pageTitle: 'Visualizar Unidade',
            linkItems: [
                {
                    name: 'Unidades Operacionais',
                    routeName: 'unidade-list'
                },
                {
                    name: '',
                    active: true
                }
            ],
            canEdit: false,
            loadingData: true,
            unidade: {
                name: null,
                smdCodeUnit: null,
                codeExternalIntegration: null,
                cnpj: null,
                regionalDepartment: null,
                city: null,
                region: null,
                responsible: null,
                email: null
            },
            linkedCities: []
        }
    },

     mounted() {
         if((getIdUnidade() === null || typeof getIdUnidade() == 'undefined') && typeof this.$route.params.id_unidade == "undefined") {
             return this.redirectToList();
        }else if(typeof this.$route.params.id_unidade != "undefined" && this.$route.params.id_unidade !== getIdUnidade()) {
            setIdUnidade(this.$route.params.id_unidade); 
            setAcronymUnidade(this.$route.params.acronym);
        }

        this.linkItems[1].name = getAcronymUnidade();
        this.pageTitle = getAcronymUnidade();

        this.loadDataUnidade();
        this.canEdit = this.verifyPermissions(actions.ATUALIZAR)
    },

    methods: {
        loadDataUnidade() {         
            this.$http.get(this.$api.unidadeParameter(getIdUnidade())).then(({ data }) => {
                setListFilter({route: this.$router.currentRoute.name, departamento:{id_departamento: data.regiao.id_departamento, descricao: data.regiao.departamento.descricao}})
                this.unidade.name = data.descricao;
                this.unidade.smdCodeUnit = data.codigo_smd;
                this.unidade.codeExternalIntegration = data.codigo_smd_integracao;
                this.unidade.cnpj = data.cnpj ? this.formatCnpj(data.cnpj) : '';
                this.unidade.regionalDepartment = data.regiao.departamento.descricao;
                
                this.unidade.city = data.nome_cidade_local_fisico;

                this.unidade.region = data.regiao.descricao;
                if(data.usuario_contato[0]) {
                    this.unidade.responsible = data.usuario_contato[0].pessoa_fisica.nome;
                    this.unidade.email = data.usuario_contato[0].email;
                }

                if(data.cidade.length > 0) {
                    this.linkedCities = data.cidade;
                }

                this.loadingData = false;
            }).catch(() => {
                this.redirectToList();
            })
        },

        formatCnpj(cnpj) {
            const cnpjFormatado = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
            return cnpjFormatado
        },

        redirectToList() {
            this.$router.push({ name: 'unidade-list' });
        },

        redirectToEdit() {
            this.$router.push(
                { 
                    name: 'unidade-edit', 
                    params: {
                        id_unidade: getIdUnidade(), 
                        acronym: getAcronymUnidade()
                    }
                }
            );
        },

        verifyPermissions(action) {
            const permissionDN = this.$can(actions[action], subjects.UNIDADE_DN)
            const permissionDR = this.$can(actions[action], subjects.UNIDADE_DR)

            return [permissionDN].includes(true) || [permissionDR].includes(true);
        }
    }
}
</script>
<style scoped>
    .cities{
        background-color: #2772C0;
        color: #ffffff;
    }

    .custom{
        padding: 5px 10px;
        margin-right: 10px;
        margin-bottom: 5px;
        font-weight: 400;
    }
</style>

